import React from "react"
import { graphql, Link } from "gatsby"
import { Flex, Box, Text } from "theme-ui"


export default function Blog({ data }) {
  //console.log(data)
  return (
  <Flex sx={{ maxWidth: '750px', margin:'auto' , padding: '0 1rem'}}>
    <Box px={1} bg='' sx={{ flex: '1 1 auto', marginTop: '20px' }}>
      <section>

        <Text as='h1' sx={{
          fontSize: 'clamp(2rem, 5vw, 3rem)'
        }}>Rando articles and snippets</Text>

        <p>{data.allMarkdownRemark.totalCount} Posts</p>
        <ul style={{listStyleType: 'none', padding:0}}>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <li key={node.id}>
              <Link
              to={node.fields.slug}>
            <Text as='h2' sx={{
              fontSize: 'clamp(1rem, 5vw, 1.33rem)',
              paddingBottom: '1.5rem'
            }}>
              {node.frontmatter.title}{" "}
              <span>
                — {node.frontmatter.date}
              </span>
            </Text>
            </Link>
          </li>
        ))}
        </ul>

      </section>
    </Box>
</Flex>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark (sort: { fields: [frontmatter___date], order: DESC }){
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
          }
          fields {
            slug
          }
          html
          excerpt
        }
      }
    }
  }
`
